@import "../../global.scss";

.navbar {
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 87.5vh;
    position: fixed;

    @include mobile {
        margin-top: 90vh;
    }

    .nav-container {
        width: 320px;
        background-color: rgba($color: black, $alpha: 0.25);
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

        .nav-items {
            display: flex;
            align-items: center;
            list-style: none;
            padding: 0;

            .nav-link {
                margin: 0 0.75rem;
                height: 3rem;
                width: 3rem;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                transition: all 0.5s ease;

                &:hover {
                    background-color: $cool;
                    border-radius: 100px;
                }

                .home-i {
                    width: 32px;
                }

                .person-i {
                    width: 48px;
                }

                .brief-i {
                    width: 44px;
                }

                .phone-i {
                    width: 34px;
                }
            }

            .active {
                background-color: $cool;
                border-radius: 100px;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}