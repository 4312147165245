@import "./global.scss";

html {
    background-color: $teal;

    body {
        height: 100%;
        width: 100%;
        overflow: hidden;

        .sections {
            height: 100vh;
            min-height: 800px;
            overflow-y: scroll;
            scroll-behavior: smooth;
            scroll-snap-type: y mandatory;

            @include mobile {
                scroll-snap-type: none;
            }
        }

        .skip-to-content-link {
            background: $teal;
            color: $egg;
            height: 30px;
            left: 50%;
            padding: 8px;
            position: absolute;
            transform: translateY(-100%);
            transition: transform 0.3s;
            text-decoration: none;
        }
          
        .skip-to-content-link:focus {
            transform: translateY(0%);
        }
    }
}