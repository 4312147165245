@import "../../global.scss";

.home {
    height: 100vh;
    min-height: 800px;
    width: 100%;
    display: flex;
    justify-content: center;
    scroll-snap-align: start;
    overflow-y: hidden;
    
    @include mobile {
        height: 110vh;
    }

    .home-container {
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -6rem;

        @include mobile {
            width: 90%;
        }

        .left {
            height: 15rem;
            flex: 2;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            margin-top: -4.5rem;
            display: none;

            @include mobile {
                margin-top: 10rem;
            }

            .v-line-left {
                width: 3px;
                height: 50px;
                background-color: $cool;
            }

            .m-icons {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .l-a {
                    margin: 2px 0;
                
                    .i-home {
                        width: 2rem;

                        &:hover {
                            width: 2.5rem;
                            transition: all 0.5s ease;
                        }
                    }
    
                    .i-email {
                        width: 1.5rem;

                        &:hover {
                            width: 2rem;
                            transition: all 0.5s ease;
                        }
                    }
                }
            }
        }

        .center {
            height: 30rem;
            flex: 8;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;

            @include mobile {
                margin-top: -15rem;
            }

            h2 {
                color: $gold;
                font-size: 1.5rem;
                font-weight: 500;
                opacity: 0;

                @include mobile {
                    text-align: center;
                }
            }

            .logo-container {
                height: 15rem;
                width: 35rem;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $cool;
                border-radius: 200px;
                opacity: 0;

                @include mobile {
                    height: 8rem;
                    width: 18.5rem;
                }

                .logo {
                    width: 27.5rem;
                    height: 10rem;

                    @include mobile {
                        width: 15rem;
                        height: 6rem;
                    }
                }
            }

            .home-cta {
                text-decoration: none;

                @include mobile {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }

                .c-a {
                    text-decoration: none;
                    opacity: 0;
                    
                    @include mobile {
                        margin: 0.5rem 0;
                    }

                    .resume-btn {
                        height: 3.5rem;
                        width: 7rem;
                        margin: 0 1rem;
                        border: 3px solid $cool;
                        border-radius: 12.5px;
                        background-color: $teal;
                        color: $cool;
                        font-size: 1.5rem;
                        cursor: pointer;
    
                        &:hover {
                            background-color: $cool;
                            color: $teal;
                            transition: all 0.5s ease;
                        }
                    }

                    .lc-btn {
                        height: 3.5rem;
                        width: 7rem;
                        margin: 0 1rem;
                        border-radius: 12.5px;
                        background-color: $teal;
                        border: 3px solid $cool;
                        color: $cool;
                        font-size: 1.5rem;
                        cursor: pointer;
    
                        &:hover {
                            background-color: $cool;
                            color: $teal;
                            transition: all 0.5s ease;
                        }
                    }
                }
            }
        }

        .right {
            height: 15rem;
            flex: 2;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            margin-top: -4.5rem;
            display: none;

            @include mobile {
                margin-top: 10rem;
                margin-right: -0.5rem;
            }

            .v-line-right {
                width: 3px;
                height: 50px;
                background-color: $cool;
            }

            span {
                font-size: 1.25rem;
                transform: rotate(90deg);
                margin-left: 5px;
                color: $cool;
            }
        }
    }
}