@import "../../global.scss";

.contact {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    scroll-snap-align: start;

    @include mobile {
        min-height: 800px;
    }

    .contact-container {
        height: 100vh;  
        width: 80%;  
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        margin-left: 3.75rem;

        @include mobile {
            margin-top: -7rem;
            margin-left: 3rem;
        }
          
        .c-title {
            height: 6rem;
            width: 19rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            margin-top: -3.5rem;

            @include mobile {
                margin-top: -3rem;
            }
      
            h3 {
                color: $cool;
                font-size: 1.125rem;  
            }
      
            .c-title-box {
                display: flex;
                align-items: center;
                justify-content: center;
               
                .h-line {
                    height: 3px;
                    width: 50px;
                    background-color: $gold;        
                }
      
                h2 {
                    color: $gold;
                    font-size: 2rem;
                    text-transform: uppercase;
                    margin: 0 1rem;
                }
            }
        }

        .contact-form-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: -2rem;

            @include mobile {
                margin-top: -5rem;
            }

            .form {
                height: 32.5rem;
                width: 60%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                margin-top: -2rem;

                .row {
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;

                    label {
                        width: 7.5rem;
                        height: 2.5rem;
                        background-color: $cool;
                        border-radius: 10px;
                        color: $egg;
                        font-size: 1.5rem;
                        font-weight: 300;
                        margin-right: 1.5rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        @include mobile {
                            height: 2rem;
                            width: 7rem;
                        }
                    }

                    input {
                        width: 30rem;
                        height: 2.5rem;
                        background-color: $teal;
                        border: 1px solid $egg;
                        border-radius: 10px;
                        color: $egg;
                        font-size: 1.5rem;
                        padding-left: 0.75rem;
                        font-weight: 300;

                        @include mobile {
                            height: 2rem;
                            width: 14rem;
                        }

                        &::placeholder {
                            color: $egg;
                            font-size: 1.5rem;
                        }
                    }

                    textarea {
                        width: 30rem;
                        height: 10rem;
                        background-color: $teal;
                        border: 1px solid $egg;
                        border-radius: 10px;
                        color: $egg;
                        font-size: 1.5rem;
                        padding-left: 0.75rem;
                        padding-top: 0.5rem;
                        font-weight: 300;

                        @include mobile {
                            width: 14rem;
                        }

                        &::placeholder {
                            color: $egg;
                            font-size: 1.5rem;
                        }
                    }
                }

                .sub-btn {
                    width: 10rem;
                    height: 3rem;
                    background-color: $teal;
                    border: 2px solid $gold;
                    border-radius: 10px;
                    color: $gold;
                    font-size: 1.5rem;
                    margin-top: -1rem;
                    transition: all 0.5s ease;
                    cursor: pointer;

                    &:hover {
                        background-color: $gold;
                        color: $teal;
                    }
                }
            }
        }
    }

    .to-footer {
        width: 3rem;
        position: relative;
        top: 18rem;
        left: 2.5rem;

        @include mobile {
            top: 16rem;
            left: -12rem;
        }
    }
}