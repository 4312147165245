@import "../../global.scss";

.portfolio {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    scroll-snap-align: start;

    @include mobile {
        height: 150vh;
    }

    .portfolio-container {
        height: 100vh;  
        width: 80%;  
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        
        .p-title {
            height: 6rem;
            width: 10rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            margin-top: -9rem;
            margin-bottom:-4rem;

            @include mobile {
                margin: 0;
            }
    
            h3 {
                color: $cool;
                font-size: 1.125rem;  
            }
    
            .p-title-box {
                display: flex;
                align-items: center;
                justify-content: center;
             
                .h-line {
                    height: 3px;
                    width: 50px;
                    background-color: $gold;        
                }
    
                h2 {
                    color: $gold;
                    font-size: 2rem;
                    text-transform: uppercase;
                    margin: 0 1rem;
                }
            }
        }
        
        .boxes-container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            gap: 2rem;

            @include mobile {
                flex-direction: column;
                margin-top: 2rem;
                gap: 1rem;
            }

            .box {
                height: 15rem;
                width: 25rem;
                background-color: $cool;
                border-radius: 30px;
                border: 1px solid $gold;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: space-evenly;

                @include mobile {
                    width: 100%;
                }

                .box-container {
                    width: 95%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 1rem;

                    .desc-container {
                        // height: 90%;
                        width: 65%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-evenly;
                        gap: 1rem;
    
                        .p-img {
                            height: 7.5rem;
                            width: 15rem;
                            border-radius: 10px;

                            @include mobile {
                                width: 90%;
                            }
                        }
    
                        h3 {
                            width: 80%;
                            font-size: 1.5rem;
                            text-align: center;
                            color: $egg;
                            font-weight: 300;
                        }
                    }
    
                    .p-btn-container {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        margin-top: 2rem;
    
                        .p-a {
                            margin-bottom: 1rem;

                            @include mobile {
                                margin-left: -0.5rem;
                            }
    
                            .p-btn-g {
                                width: 6.25rem;
                                height: 3rem;
                                font-size: 1.5rem;
                                background-color: $cool;
                                border: 2px solid $gold;
                                border-radius: 10px;
                                color: $gold;
                                transition: all 0.5s ease;
                                cursor: pointer;

                                @include mobile {
                                    width: 5.5rem;
                                }
    
                                &:hover {
                                    background-color: $gold;
                                    color: $cool;
                                }
                            }
    
                            .p-btn-l {
                                width: 6.25rem;
                                height: 3rem;
                                font-size: 1.5rem;
                                background-color: $cool;
                                border: 2px solid $teal;
                                border-radius: 10px;
                                color: $teal;
                                transition: all 0.5s ease;
                                cursor: pointer;

                                @include mobile {
                                    width: 5.5rem;
                                }
    
                                &:hover {
                                    background-color: $teal;
                                    color: $cool;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}