//colors

$egg: #e6e3d5;
$gold: #E6BA45;
$cool: #4EA8A8;
$teal: #107E7D;

//fonts
$display: freight-display-pro, serif;

//media queries
$mobile-width: 425px;
$tablet-sm-width: 768px;
$tablet-lg-width: 1024px;

@mixin mobile {
    @media (max-width: #{$mobile-width}) {
        @content;
    }
}

@mixin tablet-sm {
    @media (min-width: #{$mobile-width + 1}) and (max-width: #{$tablet-sm-width}) {
        @content;
    }
}

@mixin tablet-lg {
    @media (min-width: #{$tablet-sm-width + 1}) and (max-width: #{$tablet-lg-width}) {
        @content;
    }
}