@import "../../global.scss";

.about {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    scroll-snap-align: start;

    @include mobile {
        height: 155vh;
        margin-bottom: 5rem;
    }
    
    .about-container { 
        height: 100vh;          
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;

        @include mobile {
            height: 150vh;
            width: 100%;
            margin-top: 1rem;
        }
        
        .a-title {
            height: 6rem;
            width: 10rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            margin-top: -7rem;

            @include mobile {
                margin-top: 1.5rem;
            }
    
            h3 {
                color: $cool;
                font-size: 1.125rem;  
            }
    
            .a-title-box {
                display: flex;
                align-items: center;
                justify-content: center;
             
                .h-line {
                    height: 3px;
                    width: 50px;
                    background-color: $gold;        
                }
    
                h2 {
                    color: $gold;
                    font-size: 2rem;
                    text-transform: uppercase;
                    margin: 0 1rem;
                }
            }
        }

        .a-content {
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: -1rem;

            @include mobile {
                width: 115%;
                flex-direction: column;
            }
            
            .left {
                flex: 6;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                @include mobile {
                    margin-top: 5rem;
                }
        
                .img-container {
                    height: 25rem;
                    width: 25rem;
                    border-radius: 25px;
                    background-color: $cool; 
                    margin-top: -3rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @include mobile {
                        width: 22.5rem;
                        height: 22.5rem;
                    }
                    
                    .djld {
                        width: 20rem;
                        height: 20rem;
                        border-radius: 25px;
                        object-fit: cover;
                        object-position: 0 15%;
                    }
                }
            }

            .right {
                flex: 6;
                width: 25rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                margin-top: -3rem;

                @include mobile {
                    margin-top: 2rem;
                }

                p {
                    width: 25rem;
                    font-size: 1.25rem;
                    font-weight: 300;
                    color: $egg;
                    margin-bottom: 1rem;

                    @include mobile {
                        width: 22.5rem;
                        text-align: center;
                        font-size: 1.5rem;
                    }
                }

                .skills {
                    width: 25rem;
                    height: 15rem;
                    background-color: $cool;
                    border-radius: 25px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-evenly;

                    @include mobile {
                        width: 23rem;
                        height: 30rem;
                    }

                    h2 {
                        font-size: 2rem;
                        font-weight: 300;
                        color: $teal;
                        margin-bottom: -1rem;
                        margin-top: 0.5rem;
                    }

                    .skills-container {
                        width: 90%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-left: 1rem;

                        @include mobile {
                            width: 60%;
                            flex-direction: column;
                            align-items: flex-start;
                        }

                        .skills-column {
                            height: 10rem;
                            flex: 4;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: space-evenly;

                            &:nth-child(2) {
                                margin-left: -1rem;

                                @include mobile {
                                    margin-left: 0;
                                }
                            }

                            &:nth-child(3) {
                              margin-left: 1rem;

                              @include mobile {
                                  margin-left: 0;
                              }
                            }


                            .skill {
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                @include mobile {
                                    margin: 0.25rem 0;
                                }

                                .skill-img {
                                    width: 24px;
                                }

                                .skill-name {
                                    font-size: 1.25rem;
                                    text-transform: uppercase;
                                    margin-left: 0.25rem;
                                    color: $egg;

                                    @include mobile {
                                        font-size: 1.5rem
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}